/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2022.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Tos from "../templates/PageTemplate/Tos";
import { useUserStore } from "../../../hooks/redux/user";
import AxiosConfig from "./../../../AxiosConfig";
import LoadingIndicator from "./../../calendar/components/LoadingIndicator";
import ApiError from "./../component/ApiError";
import classnames from "classnames";
import useAuthHook from "./../hooks/useAuthHook";
import AccountNotFound from "./AccountVerificationPage/AccountNotFound";
import SignUpForm from "./AccountVerificationPage/SignUpForm";
import appConfig from "../../../appConfig";
import { useOrganizationMembersStore } from "../../../hooks/redux/organizationMembers";
import useQuery from "../../../hooks/useQuery";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import axios from "axios";
import { ucFirst } from "../../../helpers/string";
import { useDomainsStore } from "../../../hooks/redux/domains";

const PLuginNamesMapping = {
  wp: "WordPress Website",
};

const InfoLabel = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      <span className={"text-secondary"}>{ucFirst(label)}</span> {value}
    </div>
  );
};
const AccountVerificationPage = (props) => {
  const params = useQuery();

  const [submitting, setSubmitting] = useState(false);
  const { domains, updateDomain } = useDomainsStore();
  const isLoading = domains.fetch || domains.update || submitting;

  const { currentOrganization } = useCurrentOrganizationStore();

  const requestData = useMemo(
    () => ({
      callbackUrl: params.get("callback").replace(/&amp;/g, "&"),
      name: params.get("sitename"),
      adminEmail: params.get("adminemail"),
      type: params.get("type"),
      version: params.get("version"),
      url: params.get("url"),
    }),
    []
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const hasDomain = domains.collection.find(
        (domain) => domain.domain === requestData.url
      );

      if (!hasDomain) {
        await updateDomain(null, { domain: requestData.url, available: 1 });
      }

      setSubmitting(true);
      axios
        .post(
          AxiosConfig.getEndpointAddress() + "/api4/plugins/auth",
          {
            app_type: requestData.type,
            app_name: requestData.name,
          },
          AxiosConfig.getAuthConfig()
        )
        .then((response) => {
          // console.log(response);
          // return;
          window.location =
            requestData.callbackUrl + `&api_key=${response.data.api_key}`;
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
        });

      // console.log("submit");

      return false;
    },
    [domains.collection, submitting]
  );

  /**
   *
   * @type {function(*): Promise<boolean>}
   */
  const onCancel = useCallback(
    async (e) => {
      e.preventDefault();

      window.location = requestData.callbackUrl + `&error=cancelled`;

      return false;
    },
    [domains.collection, submitting]
  );

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <div className="container">
          <div className="account-logo">
            <Link to="/">
              <img
                src={`/img/branding/${appConfig.branding}/full_length_logo.png`}
                alt={appConfig.displayName}
              />
            </Link>
          </div>
          <div className={"d-flex justify-content-center"}>
            <div className="card-box card-box_auth w-40vw">
              <div className="card-title">
                <h3 className="account-header d-flex flex-column align-items-center ">
                  Authorize {PLuginNamesMapping[requestData.type]}
                  <div className={"rev-plugin-auth--icons"}>
                    <img
                      src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${requestData.url}&size=64`}
                    />
                    <span className="material-icons">add</span>
                    <img
                      src={`/img/branding/${appConfig.branding}/icon/ms-icon-310x310.png`}
                    />
                  </div>
                </h3>
              </div>
              <div className={"card-body"}>
                <p>
                  You're connecting your {PLuginNamesMapping[requestData.type]}{" "}
                  to {appConfig.displayName}. This connection allows you to
                  embed and manage widgets seamlessly on your site. Review the
                  details below and confirm the integration to get started.
                </p>
                <form action="/" onSubmit={onSubmit} onReset={onCancel}>
                  <div>
                    <h4>Website info</h4>
                    <div className={"m-b-30"}>
                      <InfoLabel label={"site name"} value={requestData.name} />
                      <InfoLabel
                        label={"admin email"}
                        value={requestData.adminEmail}
                      />
                      <InfoLabel
                        label={"version"}
                        value={requestData.version}
                      />
                      <InfoLabel label={"url"} value={requestData.url} />
                      {/*<InfoLabel*/}
                      {/*  label={"callback"}*/}
                      {/*  value={requestData.callbackUrl}*/}
                      {/*/>*/}
                    </div>
                    <div className={"m-b-30"}>
                      <h4>Account info</h4>
                      <InfoLabel
                        label={"organization name"}
                        value={currentOrganization.model.name}
                      />
                    </div>
                  </div>

                  <ApiError error={domains.updateError} />
                  <div
                    className={
                      "d-flex align-items-center justify-content-end gap-xs"
                    }
                  >
                    <button
                      type={"reset"}
                      className={"btn btn-outline-primary"}
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      type={"submit"}
                      className={"btn btn-primary"}
                      disabled={isLoading}
                    >
                      Link with {appConfig.displayName}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-center m-b-30"}>
            <div className={"card-box_auth w-40vw d-flex gap-xs"}>
              <a href={appConfig.helpdeskAddress}>
                Helpdesk <i className={"material-icons icon-xs"}>open_in_new</i>
              </a>
              {appConfig.branding === "dc" ? (
                <a href={`https://feedback.${appConfig.domain}`}>
                  Feedback{" "}
                  <i className={"material-icons icon-xs"}>open_in_new</i>
                </a>
              ) : null}
            </div>
          </div>
          <Tos />
        </div>
      </div>
    </div>
  );
};

export default AccountVerificationPage;
