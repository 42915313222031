/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/10/2024
 */

import React, { useMemo } from "react";

import { calculateDependency, isCTAllowed } from "../../../../../../../builder";
import { AvailableOptionComponents } from "../../../../../component/Builder/Group";
import { definitions as MCDefinitions } from "./../../../../../../../builder/calendar/definitions/mailchimp";
import WidgetSettingsContext from "../../../../../../../contexts/WidgetSettingsContext";

const settingsName = "mailchimp";

export const initBuilder = (template, definitions) => {
  let _values = {};

  const _definitions = definitions
    .map((i) => {
      const groups = i.groups
        .map((j) => {
          const options = j.options
            .map((option, ndx) => {
              if (
                !isCTAllowed(template, option.templates) ||
                option.integrations
              ) {
                return null;
              }

              _values[option.name] = {
                style: option.style,
                show: option.show,
                name: option.name,
                defaultValue: option.defaultValue,
                templates: option.templates,
              };

              return option;
            })
            .filter((i) => i);

          if (options.length) {
            return {
              ...j,
              options,
            };
          }
          return null;
        })
        .filter((i) => i);

      if (groups.length) {
        return {
          ...i,
          groups,
        };
      }

      return null;
    })
    .filter((i) => i);

  return {
    definitions: _definitions[0].groups,
    values: _values,
  };
};

const useOptionValue = (
  settings,
  values,
  name,
  overwriteDefaultValue = null
) => {
  if (settings[name] !== undefined) {
    if (!isNaN(settings[name]) && typeof settings[name] !== "string") {
      return Number.parseInt(settings[name]);
    }
    return settings[name];
  }

  const _values = values;

  if (overwriteDefaultValue === null && _values[name] === undefined) {
    return null;
  }

  return overwriteDefaultValue || _values[name].defaultValue;
};

export const getAllOptionValues = (widget, { definitions, values }) => {
  const optionValues = {};
  definitions.map((group) =>
    group.options.map((option) => {
      optionValues[option.name] = useOptionValue(
        widget,
        values,
        option.name,
        null
      );

      const optionOptionValue = useOptionValue(
        widget,
        values,
        option.name + "Option",
        undefined
      );

      if (optionOptionValue !== undefined) {
        optionValues[option.name + "Option"] = optionOptionValue;
      }
    })
  );

  return optionValues;
};

const Design = ({ widget, designSettings, template, onUpdateDesignProp }) => {
  const settings = widget; //designSettings.values;

  const options = designSettings.definitions.map((group, gdx) => {
    const options = group.options
      .map((option, idx) => {
        const OptionComponent = AvailableOptionComponents[option.control.kind];

        if (!OptionComponent) {
          return null;
        }

        let show = true;

        if (option.show) {
          show = calculateDependency(option.show, settings);
        }

        if (!show) {
          return null;
        }
        let checkDependency = true;
        if (option.checkDependency) {
          checkDependency = calculateDependency(
            option.checkDependency,
            settings
          );
        }

        return (
          <OptionComponent
            key={`dtgo-${settingsName}-${option.name}-${idx}`}
            updateDesignProp={onUpdateDesignProp(option)}
            settings={settings || {}}
            {...option}
            show={show}
            showDependencyWarning={checkDependency}
            template={template}
            settingsName={settingsName}
          />
        );
      })
      // remove options that don't have either a component or do not meet show criteria
      .filter((i) => i);

    const message = group.message || null;

    return (
      <div key={`dg-${gdx}`}>
        <div className="chat-line">
          <span className="chat-date">{group.label}</span>
        </div>
        {message}
        {options}
      </div>
    );
  });

  if (!options.length) {
    return null;
  }

  return <div className="form-group">{options}</div>;
};

Design.propTypes = {};

export default Design;
