/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2019.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import triggerTagEvent from "../helpers/tagmanager";

const getReferralId = () => {
  return (
    (window.Rewardful && window.Rewardful.referral) ||
    "checkout_" + new Date().getTime()
  );
};

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function initiateSubscription(
  stripeObject,
  billingData,
  planUUID,
  isYearly,
  coupon = null,
  referralCoupon = null,
  selectedPlan = null
) {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_INITIATE" });

    return stripeObject
      .createToken(billingData)
      .then((response) => {
        let axiosPromise = null;
        let hasCoupon = false;

        if (response.error && response.error.code) {
          throw response;
        }
        if (coupon && coupon.trim().length) {
          const url = "/api/coupons/apply.json";
          hasCoupon = true;
          axiosPromise = axios.post(
            url,
            {
              code: coupon,
              stripe_token: response.token.id,
              referral: getReferralId(),
              billing_data: {
                ...billingData,
                billing_contact: billingData.name,
              },
              subscription_plan_uuid: planUUID,
              is_annual: isYearly ? 1 : 0,
            },
            AxiosConfig.getAuthConfig()
          );
        } else {
          const url = "/api/subscriptions/" + planUUID + ".json";

          axiosPromise = axios.post(
            url,
            {
              stripe_token: response.token.id,
              is_yearly: isYearly ? 1 : 0,
              billing_data: billingData,
              referral: getReferralId(),
              referralCoupon: referralCoupon ? referralCoupon.id : null,
            },
            AxiosConfig.getAuthConfig()
          );
        }

        return axiosPromise
          .then((response) => {
            dispatch({
              type: "SUBSCRIPTION_INITIATE_FULFILLED",
              payload: response.data,
            });

            if (selectedPlan) {
              const GAData = {
                transaction_id: response.data.transaction_id,
                item_id: planUUID,
                account_id: response.data.organization_uuid,
                coupon: hasCoupon ? coupon.trim() : null,
                is_yearly: isYearly ? true : false,
                referral: getReferralId(),
                referralCoupon: referralCoupon ? referralCoupon.id : null,
                code: selectedPlan.code,
                name: selectedPlan.name,
                price: Number.parseFloat(
                  isYearly ? selectedPlan.price_annual : selectedPlan.price
                ),
                isBranded: selectedPlan.is_branded ? true : false,
              };

              triggerTagEvent("purchase", GAData);
            }
          })
          .catch(function (error) {
            dispatch({
              type: "SUBSCRIPTION_INITIATE_REJECTED",
              payload: error,
            });
          });
      })
      .catch((response) => {
        console.log(response);
        dispatch({
          type: "SUBSCRIPTION_INITIATE_REJECTED",
          payload: response.error,
        });
      });
  };
}

/**
 *
 * @param terminate_reason
 * @param terminate_notes
 * @returns {function(*): Promise<void>}
 * @private
 */
export function _terminateSubscription(terminate_reason, terminate_notes) {
  return function (dispatch) {
    return (
      // TODO investigate why axios.delete(url, {data, headers}) does not work properly

      axios({
        method: "DELETE",
        url: AxiosConfig.getEndpointAddress() + "/api/subscriptions.json",
        headers: AxiosConfig.getAuthConfig().headers,
        data: {
          terminate_reason,
          terminate_notes,
        },
      })
        //.delete("/api/subscriptions.json", config)
        .then((response) => {
          dispatch({
            type: "SUBSCRIPTION_TERMINATE_FULFILLED",
            payload: {},
          });
        })
        .catch((error) => {
          dispatch({
            type: "SUBSCRIPTION_TERMINATE_REJECTED",
            payload: error,
          });
        })
    );
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function terminateSubscription(
  email,
  password,
  terminate_reason,
  terminate_notes,
  isGoogleAccount = false
) {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_TERMINATE" });

    if (isGoogleAccount) {
      return _terminateSubscription(
        terminate_reason,
        terminate_notes
      )(dispatch);
    }

    const url = "/api/users/login.json";

    return axios
      .post(
        url,
        {
          email,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        return _terminateSubscription(
          terminate_reason,
          terminate_notes
        )(dispatch);
      })
      .catch(function (error) {
        dispatch({
          type: "SUBSCRIPTION_TERMINATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {(function(*): void)|*}
 */
export function terminateSubscriptionAdmin(uuid) {
  return function (dispatch) {
    dispatch({ type: "ADMIN_SUBSCRIPTION_TERMINATE", uuid });

    axios({
      method: "DELETE",
      url: "/api/subscriptions.json",
      headers: AxiosConfig.getAuthConfig().headers,
      data: {
        terminate_reason: 8,
        terminate_notes: "Terminated by admin",
        uuid,
      },
    })
      //.delete("/api/subscriptions.json", config)
      .then((response) => {
        dispatch({
          type: "ADMIN_SUBSCRIPTION_TERMINATE_FULFILLED",
          payload: {},
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADMIN_SUBSCRIPTION_TERMINATE_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function getCredits() {
  return function (dispatch) {
    dispatch({ type: "SUBSCRIPTION_CREDITS_FETCH" });

    const url = "/api/subscriptions/get_credits.json";

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_CREDITS_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SUBSCRIPTION_CREDITS_FETCH_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export function updateBillingDetails(stripeObject, billingData) {
  return function (dispatch) {
    dispatch({ type: "BILLING_DETAILS_UPDATE" });

    return stripeObject
      .createToken({
        billingData,
      })
      .then((response) => {
        const url = "/api/payment_methods/update_card_details.json";

        return axios.post(
          url,
          {
            stripe_card_token: response.token.id,
            billing_data: {
              ...billingData,
            },
          },
          AxiosConfig.getAuthConfig()
        );
      })
      .then((response) => {
        dispatch({
          type: "BILLING_DETAILS_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BILLING_DETAILS_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
}
