import { CT, MCT } from "../../../consts";
import appConfig from "../../../appConfig";
import ExpandableAlert from "../../../apps/admin/component/ExpandableAlert";
import React from "react";

const showIfEverGreen = [
  {
    field: "eventsToShowOption",
    value: "evergreen",
    defaultValue: "evergreen",
  },
];

const showIfStatic = [
  {
    field: "eventsToShowOption",
    value: "defaults",
    defaultValue: "evergreen",
  },
];

export const definitions = {
  title: "Mailchimp",
  icon: "padding",
  groups: [
    {
      label: "Events control",
      message: (
        <ExpandableAlert
          errorLevel={"info"}
          header={"Difference between static and evergreen options?\n"}
          body={
            <span>
              Both options start with your calendar defaults and can be modified
              further.
              <br />
              <strong>Static (one-time)</strong> → same newsletter each time.
              Example: one-time or non-repeating events or event series such as
              Easter program.
              <br />
              <strong>Evergreen (recurring)</strong> → updated newsletter each
              time. Example: what's happening this week.
              <br />
              <br />
              {appConfig.branding === "dc" ? (
                <a
                  href={
                    "http://help.display.church/en/articles/10068292-mailchimp-email-template-alpha"
                  }
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Follow this link to learn more
                </a>
              ) : null}
            </span>
          }
        ></ExpandableAlert>
      ),
      options: [
        {
          title: "Method to show events",
          templates: [MCT.list, MCT.compact],
          name: "eventsToShow",
          defaultValue: "evergreen",
          tooltip: null,

          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Static (one-time)",
                  value: "defaults",
                  template: [MCT.list, MCT.compact],
                },
                {
                  title: "Evergreen (recurring)",
                  value: "evergreen",
                  template: [MCT.list, MCT.compact],
                },
              ],
            },
          },
        },
        {
          title: "Show events starting from",
          templates: [MCT.list, MCT.compact],
          name: "startDate",
          defaultValue: "today",
          tooltip: null,
          show: showIfEverGreen,
          control: {
            kind: "dropdown",
            options: {
              dropdownOptions: [
                { label: "Today", value: "today" },
                { label: "next Monday", value: "mon" },
                { label: "next Tuesday", value: "tue" },
                { label: "next Wednesday", value: "wed" },
                { label: "next Thursday", value: "thu" },
                { label: "next Friday", value: "fri" },
                { label: "next Saturday", value: "sat" },
                { label: "next Sunday", value: "sun" },
                {
                  label: "first day of the next month",
                  value: "first_day_of_the_next_month",
                },
              ],
            },
          },
        },
        {
          title: "Limit events by number of days",
          templates: [MCT.list, MCT.compact],
          name: "daysLimit",
          defaultValue: "all",
          tooltip: null,
          show: showIfStatic,
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Use calendar defaults",
                  value: "all",
                  template: [MCT.list, MCT.compact],
                },

                {
                  title: "Show events for the next",
                  value: "daysLimit",
                  template: [MCT.list, MCT.compact],
                  input: {
                    defaultValue: 7,
                    postLabel: "days",
                    type: "number",
                    min: 1,
                    max: 31,
                  },
                },
                {
                  title: "Show events within this time range",
                  value: "date_range",
                  template: [MCT.list, MCT.compact],
                  input: {
                    type: "daterange",
                    defaultValue: "",
                  },
                },
              ],
            },
          },
        },
        {
          title: "Limit events by number of days",
          templates: [MCT.list, MCT.compact],
          name: "daysLimit",
          defaultValue: "all",
          tooltip: null,
          show: showIfEverGreen,
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Use calendar defaults",
                  value: "all",
                  template: [MCT.list, MCT.compact],
                },

                {
                  title: "Show events for the next",
                  value: "daysLimit",
                  template: [MCT.list, MCT.compact],
                  input: {
                    defaultValue: 7,
                    postLabel: "days",
                    type: "number",
                    min: 1,
                    max: 31,
                  },
                },
              ],
            },
          },
        },
        {
          title: "Limit events",
          templates: [MCT.list, MCT.compact],
          name: "eventsLimit",
          defaultValue: "all",
          tooltip: null,
          control: {
            kind: "radio",
            options: {
              radioOptions: [
                {
                  title: "Use calendar defaults",
                  value: "all",
                  template: [MCT.list, MCT.compact],
                },
                {
                  title: "Show only",
                  value: "upcoming-limit",
                  template: [MCT.list, MCT.compact],
                  input: {
                    defaultValue: 3,
                    postLabel: "events",
                    type: "number",
                    min: 1,
                    max: 31,
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      label: "Display information",
      options: [
        {
          title: "Show event title",
          templates: [MCT.list, MCT.compact],
          name: "showTitle",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show date",
          templates: [MCT.list, MCT.compact],
          name: "showDate",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show location",
          templates: [MCT.list, MCT.compact],
          name: "showLocation",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show description",
          templates: [MCT.list, MCT.compact],
          name: "showDescription",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Limit description length",
          templates: [MCT.list, MCT.compact],
          name: "limitDescription",
          defaultValue: 1,
          tooltip: null,
          show: [
            {
              field: "showDescription",
              value: 1,
              defaultValue: 1,
            },
          ],
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show Read more",
          templates: [MCT.list, MCT.compact],
          name: "showReadMore",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show image",
          templates: [MCT.list, MCT.compact],
          name: "showImage",
          defaultValue: 1,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
      ],
    },
    {
      label: "Design",
      options: [
        {
          title: "Background",
          templates: [MCT.list, MCT.compact],
          name: "bgColor",
          defaultValue: "#ffffff",
          style: [
            {
              selector: ["._____NONE"],
              property: "background-color",
            },
          ],
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
  ],
};
