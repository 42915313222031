import AxiosConfig from "../../../../../../../../AxiosConfig";
import {
  getFetchEventsParams,
  getSelectedDate,
} from "../../../../../../../../helpers/calendar";

/**
 * @param {string} uuid
 * @param settings
 * @param widgetModel
 *
 * @returns {`${string}/feeds/calendar/${string}.rss?${string}`}
 */
export const getRssFeedUrl = (uuid, settings, widgetModel) => {
  const queryParams = {
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const widgetEventsNumberOption =
    widgetModel.widget_settings?.design?.eventsNumberOption ||
    widgetModel.builder?.design?.values?.eventsNumber?.defaultValue ||
    "upcoming";
  const widgetEventsNumberCount =
    widgetModel.widget_settings?.design?.eventsNumber;

  const eventsNumberOption = settings?.daysLimitOption || "all";
  const eventsNumberCount = settings?.daysLimit;

  const isInfiniteScroll = widgetModel.widget_settings?.design?.infiniteScroll;

  const useCustomDateRange = eventsNumberOption === "date_range";

  const currentEventsNumberOption = useCustomDateRange
    ? eventsNumberOption
    : widgetEventsNumberOption;
  const currentEventsNumberCount = useCustomDateRange
    ? eventsNumberCount
    : widgetEventsNumberCount;

  const fetchParams = getFetchEventsParams(
    [],
    getSelectedDate(currentEventsNumberOption, currentEventsNumberCount),
    currentEventsNumberOption,
    currentEventsNumberCount,
    widgetModel.template
  );

  const endDate = fetchParams.endDate
    ? new Date(`${fetchParams.endDate}T00:00:00`).getEndOfDay().getUnixTime()
    : undefined;
  if (
    !settings?.eventsToShowOption ||
    settings?.eventsToShowOption === "evergreen"
  ) {
    queryParams.from_day = settings.startDate;

    if (settings?.daysLimitOption !== "daysLimit") {
      queryParams.end_date = endDate;
    }
  } else {
    const startDate = fetchParams.startDate
      ? new Date(`${fetchParams.startDate}T00:00:00`)
          .getStartOfDay()
          .getUnixTime()
      : undefined;

    queryParams.start_date = startDate;
    queryParams.end_date = endDate;
    queryParams.limit = isInfiniteScroll ? 10 : fetchParams.limit;
    queryParams.page = fetchParams.page;
  }

  if (settings?.daysLimitOption === "daysLimit") {
    queryParams.days_ahead = settings.daysLimit;
  }

  if (settings?.limitDescription === 1) {
    queryParams.trunc_desc = 1;
  }

  return `${AxiosConfig.getEndpointAddress()}/feeds/calendar/${uuid}.rss?${AxiosConfig.objectToURLQuery(
    queryParams
  )}`;
};

export const RSSFeedToObj = (rssDom) => {
  if (rssDom.hasChildNodes()) {
    const items = rssDom.getElementsByTagName("item");

    if (!items.length) {
      console.log("RSS feed has no items");
      return [];
    }

    const events = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const link = item
        .getElementsByTagName("link")[0]
        ?.nextSibling?.textContent?.trim();
      events.push({
        title: item.getElementsByTagName("title")[0].textContent,
        description: item.getElementsByTagName("description")[0].textContent,
        link,
        startDate: item.getElementsByTagName("pubDate")[0].textContent,
        imgUrl: item
          .getElementsByTagName("media:content")[0]
          ?.getAttribute("url"),
        location: item.getElementsByTagName("category")[0]?.textContent ?? null,
      });
    }

    return events;
  } else {
    console.log("No child nodes found in RSS feed");
  }

  return [];
};
