/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

import moment from "moment";
import EventList from "./EventsList/EventList";
import MonthlyView from "./MonthlyView/MonthlyView";
import PropTypes from "prop-types";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import WeeklyView from "./WeeklyView/WeeklyView";
import CardView from "./CardView/CardView";
import { CT, PT } from "../../../consts";
import {
  getFetchEventsParams,
  getSelectedDate,
  isCTAllowed,
} from "../../../helpers/calendar";
import CardSlider from "./CardView/CardSlider";
import { useEventsStore } from "../../../hooks/redux/events";
import IntegrationContext from "../strategies/IntegrationContext";
import { getAllOptionValues } from "../../../builder";
import { WT } from "../../admin/consts/widget";
import { useAppStore } from "../../../hooks/redux/app";
import classnames from "classnames";
import useAgressiveResponsiveWidget from "../../../hooks/useAgressiveResponsiveWidget";
import { filtersToQueryParam } from "../../../helpers/filters";
import DetailedList from "./DetailedList/DetailedList";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import BubbleView from "./Bubble/BubbleView";
import FeaturedHighlights from "../FeaturedHighlights";
import { useFeaturedEventsStore } from "../../../hooks/redux/featuredEvents";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import BrandingHeader from "./Branding/BrandingHeader";
import { useTranslation } from "react-i18next";
import EventSchema from "./StructuredData/EventSchema";
import {
  getWidgetItemSlugFromUrl,
  getWidgetUUIDFromUrl,
} from "../../../helpers/linking";
import SelectedEventModal from "./SelectedEventModal";
import CalendarLinkingContext from "../../../contexts/CalendarLinkingContext";
import useEventLinking from "../hooks/useEventLinking";
import useQuery from "../../../hooks/useQuery";

const CalendarComponentsMapping = {
  [CT.card]: CardView,
  [CT.cardSlider]: CardSlider,
  [CT.cardList]: CardView,
  [CT.monthly]: MonthlyView,
  [CT.weekly]: WeeklyView,
  [CT.eventList]: EventList,
  [CT.detailedList]: DetailedList,
  [CT.bubble]: BubbleView,
};

const Calendar = (props) => {
  const { calendar } = useCalendarStore();
  const [requestedWithSingleEvent, setRequestedWithSingleEvent] = useState(
    getWidgetItemSlugFromUrl("event")
  );

  //console.log("requestedWithSingleEvent", requestedWithSingleEvent);

  const hasValidProps = calendar.model.slug ? true : false;
  const hasSingleEvent =
    hasValidProps &&
    props.singleEventSlug &&
    calendar.model.uuid === getWidgetUUIDFromUrl(WT.calendar)
      ? true
      : false;
  // console.log("hasSingleEvent", hasSingleEvent, calendar?.model?.slug);

  const [searchKeyword, setSearchKeyword] = useState("");

  const {
    events,
    fetchEvents,
    fetchFeaturedEvents,
    fetchEvent,
    RSVPEvent,
    resetEventsCollection,
    searchEvents,
  } = useEventsStore();

  const { featuredEvents } = useFeaturedEventsStore();

  const { app } = useAppStore();
  const { i18n } = useTranslation();

  const widgetId = `dce_${WT.calendar}__${calendar.model.uuid}`;
  const query = useQuery();

  const integrationContext = useContext(IntegrationContext);

  const selectedEvent = useMemo(() => {
    if (
      props.singleEventSlug &&
      calendar.model.uuid === query.get("calendar")
    ) {
      return (
        events.collection.find((i) => i.slug === props.singleEventSlug) ||
        featuredEvents.collection.find((i) => i.slug === props.singleEventSlug)
      );
    }

    return null;
  }, [props.singleEventSlug, events.collection, featuredEvents.collection]);

  const calendarSettings = getAllOptionValues(
    calendar.model,
    props.designSettings
  );

  const calendarHighlightsSettings = getAllOptionValues(
    calendar.model,
    "highlights"
  );

  const calendarLinking = useEventLinking(
    calendar.model.uuid,
    selectedEvent,
    calendarSettings,
    calendarHighlightsSettings
  );

  const eventsNumberOption = calendarSettings["eventsNumberOption"] || "all";
  const eventsNumberCount = calendarSettings["eventsNumber"];
  const hasInfiniteScroll = calendarSettings["infiniteScroll"];
  const showMonthSelector = calendarSettings["showUpcomingNavigation"];
  const hasAggressiveResponsiveness =
    Number.parseInt(calendarSettings["WAggressiveResp"]) === 1;
  const showAI = calendarSettings["aiEnabled"];
  const aiPrefetchEvents = calendarSettings["aiPrefetchEvents"];
  const skipInitialFetch = showAI && aiPrefetchEvents === 0;

  // reset the events collection if kind of event filter has changed
  useDidMountEffect(() => {
    // reset collection only if model is available
    if (calendar.model.template) {
      resetEventsCollection();
    }
  }, [eventsNumberOption, eventsNumberCount, hasInfiniteScroll]);

  useAgressiveResponsiveWidget(
    widgetId,
    600,
    "dce-ar-responsiveness",
    hasAggressiveResponsiveness
  );

  const [state, setState] = useState({
    selectedDate: getSelectedDate(eventsNumberOption, eventsNumberCount),
    selectedFilters: integrationContext.availableFilters.reduce(
      (i, j) => ({ ...i, [j]: {} }),
      {}
    ),

    initialized: hasValidProps,
    page: 1,
  });

  const featuredHighlightsEventsLimit = useMemo(() => {
    return calendar.model.widget_settings.featured_events_limit || 0;
  }, [calendar.model.widget_settings.featured_events_limit]);

  useEffect(() => {
    if (calendar.model.slug) {
      setState({
        ...state,
        initialized: true,
      });

      window.__DCE[WT.calendar].init = false;
    }
  }, [calendar.model.slug]);

  useEffect(() => {
    if (hasSingleEvent) {
      fetchEvent(props.singleEventSlug);
    } else if (hasValidProps) {
      if (!skipInitialFetch) {
        if (hasInfiniteScroll) {
          onLoadMore(1);
        } else {
          _fetchEvents();
        }
      }
    }

    return () => {
      resetEventsCollection();
    };
  }, []);

  useEffect(() => {
    if (!hasSingleEvent && requestedWithSingleEvent) {
      setRequestedWithSingleEvent(false);
    }
  }, [hasSingleEvent]);

  useEffect(() => {
    setState({
      ...state,
      selectedDate: getSelectedDate(eventsNumberOption, eventsNumberCount),
    });
  }, [eventsNumberOption]);

  useDidMountEffect(() => {
    if (calendar.model.template) {
      if (!hasSingleEvent) {
        if (hasInfiniteScroll) {
          onLoadMore(1, false);
        } else {
          resetEventsCollection();
          _fetchEvents();
        }
      }
    }
  }, [
    state.selectedDate.unix(),
    state.page,
    state.selectedFilters,
    calendar.model.show_private_events,
    calendar.model.show_events_with_images,
    calendar.model.show_private_events,
    calendar.model.filter_settings,
    calendar.model.has_featured_events,
    calendar.model.has_group_events_included,
    eventsNumberCount,
    hasInfiniteScroll,
    searchKeyword,
    requestedWithSingleEvent,
  ]);

  useEffect(() => {
    // console.log(">>>>>");

    if (requestedWithSingleEvent && events.fetchSuccess) {
      // console.log("setting selected date");
      setState({
        ...state,
        selectedDate: moment(events.collection[0].start_time * 1000),
      });
    }
  }, [requestedWithSingleEvent, events.fetchSuccess]);

  // console.log("selected Date", state.selectedDate.format("YYYY-MM-DD"));

  /**
   *
   */
  const _fetchEvents = async () => {
    if (events.fetch || hasSingleEvent) {
      return false;
    }

    const fetchParams = getFetchEventsParams(
      filtersToQueryParam(state.selectedFilters),
      state.selectedDate.clone(),
      eventsNumberOption,
      eventsNumberCount,
      calendar.model.template,
      state.page
    );

    return await fetchEventsWithFeaturedEvents(
      props.calendarUUID,
      fetchParams.startDate,
      fetchParams.endDate,
      fetchParams.limit,
      fetchParams.page,
      fetchParams.filters
    );
  };

  const fetchEventsWithFeaturedEvents = async (
    calendarUUID,
    from,
    to,
    limit,
    page,
    filters
  ) => {
    if (Boolean(calendar.model?.widget_settings?.has_feature_highlights)) {
      const featuredParams = {};
      if (!props.embedded) {
        featuredParams.internal = 1;
      }

      fetchFeaturedEvents(
        calendarUUID,
        from,
        null,
        calendar.model?.widget_settings?.featured_events_limit || 12,
        1,
        filters,
        false,
        featuredParams
      );
    }

    const params = {
      include_featured: Boolean(calendar.model.has_featured_events),
    };

    if (searchKeyword.length) {
      params.keyword = searchKeyword;
    }

    if (!props.embedded) {
      params.internal = 1;
    }
    fetchEvents(calendarUUID, from, to, limit, page, filters, false, params);
  };

  /**
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const fetchEventsByQuery = useCallback(async (query) => {
    searchEvents(props.calendarUUID, query);
  }, []);

  /**
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const fetchEventsByKeyword = useCallback(
    async (keyword) => {
      setSearchKeyword(keyword);
    },
    [searchKeyword]
  );

  /**
   *
   * @param number month either 1 or -1 to change the month
   */
  const onSelectedDateChange = useCallback(
    (direction) => {
      if (hasInfiniteScroll) {
        resetEventsCollection();
      }

      const updatedDate = moment(state.selectedDate).add(direction, "month");

      // if it allows to show only upcoming events then don't allow to select past dates
      if (eventsNumberOption === "upcoming") {
        //console.log("updatedDate", updatedDate);
        if (updatedDate.isBefore(moment(), "month")) {
          return false;
        }
      }

      setState({
        ...state,
        selectedDate: updatedDate.startOf("month"),
      });
    },
    [hasInfiniteScroll, state]
  );

  /**
   *
   * @param date
   */
  const onSelectedWeekChange = (date) => {
    setState({
      ...state,
      selectedDate: date,
    });
    resetEventsCollection();
  };

  /**
   *
   * @param direction
   */
  const onUpcomingNavigationChange = useCallback(
    (direction) => {
      if (direction == 1) {
        const maxPages = Math.ceil(events.totalRows / eventsNumberCount);

        if (events.page >= maxPages) {
          return false;
        }
      }

      let page = state.page + direction;

      setState({
        ...state,
        page: page <= 1 ? 1 : page,
      });
    },
    [events.totalRows, events.page, eventsNumberCount, state]
  );

  /**
   *
   * @param location
   */
  const onChangeFilter = (filter) => {
    const selectedFilters = { ...state.selectedFilters };
    selectedFilters[filter.kind] = filter;

    setState({
      ...state,
      selectedFilters,
    });
  };

  /**
   *
   * @param event
   */
  const onRSVPEvent = (event, login, password, status) => {
    RSVPEvent(calendar.model.uuid, event.slug, login, password, status);
  };

  /**
   *
   * @param page
   * @param preserveExistingEvents
   */
  const onLoadMore = (page, preserveExistingEvents = true) => {
    const showUpcomingOnlyNavigation = eventsNumberOption === "upcoming";

    const endMonth =
      showMonthSelector && isCTAllowed(calendar.model.template, [CT.eventList])
        ? moment(state.selectedDate).endOf("month").format("YYYY-MM-DD")
        : null;

    const startDay = showUpcomingOnlyNavigation
      ? state.selectedDate.isBefore(moment())
        ? moment().format("YYYY-MM-DD")
        : moment(state.selectedDate).format("YYYY-MM-DD")
      : moment(state.selectedDate).format("YYYY-MM-DD");

    const filters = filtersToQueryParam(state.selectedFilters);

    const params = {
      include_featured: Boolean(calendar.model.has_featured_events),
    };

    if (searchKeyword.length) {
      params.keyword = searchKeyword;
    }

    if (!props.embedded) {
      params.internal = 1;
    }

    fetchEvents(
      props.calendarUUID,
      startDay,
      endMonth,
      10,
      page,
      filters,
      preserveExistingEvents,
      params
    );
  };

  /**
   * @param _page comes from the infinite scroller but it will not be accurate
   * if e.g. filters has changed. Such scenario will fetch 1st page from the backend but
   * on scroll the infinite scroller would return next number it had remembered rather than 2.
   * bottom line- do not rely on _page.
   */
  const onLoadMoreInfiniteScroller = (_page) => {
    if (events.collection.length >= events.totalRows) {
      return false;
    }

    const page = events.page + 1;

    onLoadMore(page);
  };

  const calendarType = calendar.model.template; /*props.singleEventSlug
    ? CT.eventList
    : ;*/

  const _onLoadMore =
    calendarType === CT.eventList
      ? props.singleEventSlug
        ? null
        : onLoadMoreInfiniteScroller
      : onLoadMoreInfiniteScroller;

  const _onSelectedDateChange =
    calendarType === CT.weekly ? onSelectedWeekChange : onSelectedDateChange;

  const CalendarComponent = CalendarComponentsMapping[calendarType];

  const featuredHighlights =
    Boolean(calendar.model.has_featured_events) &&
    Boolean(calendar.model.widget_settings.has_feature_highlights) ? (
      <FeaturedHighlights
        key={calendar.model.widget_settings.featured_template || CT.bubble}
        embedded={props.embedded}
        calendar={calendar}
        events={featuredEvents}
        initialized={state.initialized}
        selectedFilters={state.selectedFilters}
        selectedDate={state.selectedDate}
        onRSVPEvent={onRSVPEvent}
        onChangeFilter={onChangeFilter}
        previewType={app.previewType}
        selectedEvent={props.singleEventSlug}
        eventsLimit={featuredHighlightsEventsLimit}
      />
    ) : null;

  const calendarComponent = (
    <React.Fragment>
      <BrandingHeader calendar={calendar} embedded={props.embedded} />
      {/*<BackToCalendar
        embedded={props.embedded}
        eventSlug={props.singleEventSlug}
      />*/}
      {featuredHighlights}
      <CalendarComponent
        embedded={props.embedded}
        calendar={calendar}
        events={events}
        initialized={state.initialized}
        searchEvents={fetchEventsByQuery}
        fetchEvents={_fetchEvents}
        selectedFilters={state.selectedFilters}
        selectedDate={state.selectedDate}
        onRSVPEvent={onRSVPEvent}
        onChangeFilter={onChangeFilter}
        previewType={app.previewType}
        onUpcomingNavigationChange={onUpcomingNavigationChange}
        selectedEvent={selectedEvent}
        onLoadMore={_onLoadMore}
        onSelectedDateChange={_onSelectedDateChange}
        onKeywordSearch={fetchEventsByKeyword}
        singleEventSlug={props.singleEventSlug}
      />
      <SelectedEventModal
        selectedEvent={selectedEvent}
        embedded={props.embedded}
        onRSVPEvent={onRSVPEvent}
      />
    </React.Fragment>
  );

  const content =
    app.previewType === PT.mobile ? (
      <div className={"mobile-preview--smartphone"}>{calendarComponent}</div>
    ) : (
      calendarComponent
    );

  const eventsSchema = events.collection.length ? (
    <EventSchema events={events.collection} />
  ) : null;

  return (
    <WidgetSettingsContext.Provider value={calendarSettings}>
      <CalendarLinkingContext.Provider value={calendarLinking}>
        {eventsSchema}
        <div
          className={classnames(
            widgetId,
            WT.calendar,
            `dce-${WT.calendar}`,
            `dce-${calendarType}`,
            {
              "mobile-preview": app.previewType === PT.mobile,
              /* "single-event": hasSingleEvent,*/
            }
          )}
          lang={i18n.language}
        >
          {content}
        </div>
      </CalendarLinkingContext.Provider>
    </WidgetSettingsContext.Provider>
  );
};

Calendar.defaultProps = {
  designSettings: "design",
};

Calendar.propTypes = {
  embedded: PropTypes.bool,
  singleEventSlug: PropTypes.string,
  calendarUUID: PropTypes.string.isRequired,
  designSettings: PropTypes.string,
};

export default Calendar;
